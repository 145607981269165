





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
.clickable {
  cursor: pointer !important;
}
.nclickable {
  cursor: default !important;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-clip {
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
}
